import React, {useEffect, useState} from 'react';
import './App.css';
import "@fortawesome/fontawesome-free/js/all"

const calculateValidPositions = function (from: Position) {
    let validPositions: Position[] = [];

    function testAndAddPosition(validPositions: Position[], from: Position, deltaX: number, deltaY: number) {
        let newX = from.x + deltaX;
        let newY = from.y + deltaY;

        if (caseOnBoard({x: newX, y: newY})) {
            validPositions.push({x: newX, y: newY});
        }

    }

    testAndAddPosition(validPositions, from, -1, -2);
    testAndAddPosition(validPositions, from, -1, 2);
    testAndAddPosition(validPositions, from, 1, -2);
    testAndAddPosition(validPositions, from, 1, 2);

    testAndAddPosition(validPositions, from, -2, -1);
    testAndAddPosition(validPositions, from, -2, 1);
    testAndAddPosition(validPositions, from, 2, -1);
    testAndAddPosition(validPositions, from, 2, 1);

    return validPositions;
}

function caseOnBoard(position: Position): boolean {
    return (position.x >= 1 && position.x <= 8) && (position.y >= 1 && position.y <= 8);
}

function samePosition(position1: Position, position2: Position) {
    return position1.x === position2.x && position1.y === position2.y;
}

function positionInArray(position: Position, positionList: Position[]): boolean {
    for (let positionInList of positionList) {
        if (samePosition(positionInList, position)) {
            return true;
        }
    }

    return false;
}


function moveIsValid(from: Position, target: Position): boolean {
    return positionInArray(target, calculateValidPositions(from));
}

const hasPlayed = function (played: Position[], target: Position): boolean {
    return positionInArray(target, played);
}

const calculateValidAndUnPlayedPositions = function (played: Position[], current: Position) {
    let availablePositions: Position[] = [];

    for (let validPosition of calculateValidPositions(current)) {
        let found = false;
        for (let playedPosition of played) {
            if (samePosition(validPosition, playedPosition)) {
                found = true;
                break;
            }
        }

        if (!found) {
            availablePositions.push(validPosition);
        }
    }

    return availablePositions;
}

function Square(props: { onClick: () => void, knightPosition?: Position, squarePosition: Position, playedPositions: Position[] }) {
    const current = props.knightPosition != null && samePosition(props.knightPosition, props.squarePosition);
    const allowed = props.knightPosition === undefined || moveIsValid(props.knightPosition, props.squarePosition);
    return <div
        className={`square ${allowed ? "allowed" : ""} ${current ? "knight" : ""} ${hasPlayed(props.playedPositions, props.squarePosition) ? "played" : ""}`}
        onClick={props.onClick}>
        {current && <div><i className="fas fa-chess-knight"/></div>}
    </div>
}

function Board(props: {
    squareClick: (x: number, y: number) => void,
    knightPosition?: Position,
    playedPositions: Position[]
}) {
    const lines: JSX.Element[] = [];

    for (let i = 1; i <= 8; i++) {
        const cells: JSX.Element[] = [];

        for (let j = 1; j <= 8; j++) {
            cells.push(<Square key={i + ";" + j}
                               knightPosition={props.knightPosition}
                               squarePosition={{x: i, y: j}}
                               playedPositions={props.playedPositions}
                               onClick={() => props.squareClick(i, j)}/>);
        }

        lines.push(
            <div className={"line"} key={i}>
                {cells}
            </div>
        );
    }

    return <div className={"board"}>{lines}</div>;
}

interface Position {
    x: number;
    y: number;
}

function Rules() {
    return (<p className={"rules"}>
        <u>Rules</u><br />
        We play a chess <i className="fas fa-chess-knight"/>.<br />
        Each time you move from a square, it is destroyed.<br />
        The first player who is no longer able to move loses.
    </p>);
}
function App() {
    const [knightPosition, setKnightPosition] = useState<Position | undefined>(undefined)
    const [played, setPlayed] = useState<Position[]>([])
    const [endGame, setEndGame] = useState(false);
    const [yourTurn, setYourTurn] = useState(true);

    useEffect(() => {
        if (knightPosition && calculateValidAndUnPlayedPositions(played, knightPosition).length === 0) {
            setEndGame(true);
        }
    }, [knightPosition, played])

    useEffect(() => {
        if (played.length % 2 === 0) {
            setYourTurn(true)
        } else {
            setYourTurn(false)
        }
    }, [played])

    const retry = function () {
        setKnightPosition(undefined);
        setPlayed([]);
        setEndGame(false);
        setYourTurn(false)
    }

    const doPlay = function (targetPosition: Position) {
        setKnightPosition(targetPosition);
        setPlayed(prevPlayed => [...prevPlayed, targetPosition])
    }

    const computerPlays = function (lastPosition: Position) {
        let nx = Math.round((lastPosition.x / 2)) * 2 - (1 - lastPosition.x % 2)
        let ny = (lastPosition.y - 1) % 4 + 1 < 3 ? lastPosition.y + 2 : lastPosition.y - 2
        doPlay({x: nx, y: ny})
    }

    const checkAndPlay = function (targetPosition: Position) {
        if (knightPosition === undefined) {
            doPlay(targetPosition)
            setTimeout(() => computerPlays(targetPosition), 550)
        } else {
            if (moveIsValid(knightPosition, targetPosition) && !hasPlayed(played, targetPosition) && played.length % 2 === 0) {
                doPlay(targetPosition)
                setTimeout(() => computerPlays(targetPosition), 550)
            }
        }
    }

    return (
        <>
            <div className={"game"}>
                <Board squareClick={(x, y) => checkAndPlay({x, y})}
                       playedPositions={played}
                       knightPosition={knightPosition}/>
            </div>
            <div className={"turn"}>
                {endGame || <>{yourTurn ? <>Pick a square</> : <>My turn</>}</>}
            </div>
            <div className={"end"}>
                {endGame ? <><p className={"lose"}> You lose.<br /><button onClick={retry}>Play again</button></p> </> : <Rules/>}

            </div>
        </>
    );
}

export default App;
